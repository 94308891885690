import React, { useState } from 'react';
import { CarouselProvider } from 'pure-react-carousel';
import Interweave from 'interweave';
import cc from 'classcat';
import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import styles from './comment.module.sass';
import { Anchor } from '../Text';
import { Avatar } from '../Avatar';
import { Button } from '../Button';

const options = {
  buttons: {
    showThumbnailsButton: false,
    showAutoplayButton: false,
    showFullscreenButton: true,
  },
  thumbnails: {
    showThumbnails: false,
  },
};
export const Comment = ({
  key,
  inicials,
  name,
  date,
  children,
  liketext,
  disliketext,
  reviewPhoto,
  userPick,
  ship,
  link,
  stars,
  starShip,
  isMobile,
  isModal,
  ...props
}) => {
  const [showExcDescription, setShowExcDescription] = useState(false);
  return (
    <div className={styles.wrapper} {...props}>
      <div className={styles.allInfo}>
        <div className={styles.info}>
          <Avatar
            className={styles.avatar}
            name={inicials}
            userPick={userPick}
          />
          <div className={styles.user}>
            <p>{name}</p>
            <time>{date}</time>
          </div>
        </div>
        {!isModal && (
          <div className={styles.rating}>
            {ship && (
              <div className={styles.link}>
                <Anchor
                  href={`/ships/${link}`}
                  variant="reviewHref"
                  target="_blank"
                >
                  <div className={styles.star}>
                    {`${ship} ${starShip > 0 ? `${starShip}` : ''}`}
                    {starShip > 0 && (
                      <img
                        src={`/images/starBlue.svg`}
                        className={styles.star}
                        alt="звезда"
                      />
                    )}
                  </div>
                </Anchor>{' '}
              </div>
            )}
          </div>
        )}
      </div>
      <div
        className={cc([showExcDescription ? [] : [styles.excClipper]])}
        key={key}
      >
        <Interweave content={`${children}`} />
      </div>
      {isMobile
        ? children.length > 100 && (
            <div className={styles.buttonClipper}>
              {!showExcDescription ? (
                <Button
                  onClick={() => {
                    setShowExcDescription(!showExcDescription);
                  }}
                  key={key}
                  view="reviewsButton"
                >
                  Читать далее
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setShowExcDescription(!showExcDescription);
                  }}
                  key={key}
                  view="reviewsButton"
                >
                  Свернуть отзыв
                </Button>
              )}
            </div>
          )
        : children.length > 300 && (
            <div className={styles.buttonClipper}>
              {!showExcDescription ? (
                <Button
                  onClick={() => {
                    setShowExcDescription(!showExcDescription);
                  }}
                  key={key}
                  view="reviewsButton"
                >
                  Читать далее
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setShowExcDescription(!showExcDescription);
                  }}
                  key={key}
                  view="reviewsButton"
                >
                  Свернуть отзыв
                </Button>
              )}
            </div>
          )}

      {reviewPhoto && (
        <SimpleReactLightbox>
          <SRLWrapper options={options}>
            {' '}
            <CarouselProvider totalSlides={reviewPhoto && reviewPhoto.length}>
              <div className={styles.imgAll}>
                {reviewPhoto.map(i => (
                  <img
                    className={styles.img}
                    src={`/images/reviews/${i}`}
                    alt="фото туристов"
                  />
                ))}{' '}
              </div>
            </CarouselProvider>
          </SRLWrapper>
        </SimpleReactLightbox>
      )}
    </div>
  );
};
