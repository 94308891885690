import React from 'react';
import styles from './intro.module.sass';
import { Anchor } from '../Text';
// import GlobusIcon from '../../public/icons/globus.svg';
// import ArrowIcon from '../../public/icons/arrow.svg';
import { Icon } from '../Icon';
import { IconSVG } from '../SpriteSVG';

export const Intro = ({ title, link, seoText }) => (
  <div className={styles.intro}>
    <h1 className={styles.introTitle}>{title}</h1>
    <div className="visually-hidden">{seoText}</div>
    {link && (
      <Anchor href={link.href} variant="linkAsbutton2" target="_blank">
        <span className={styles.introLink}>
          <Icon
            icon={
              <IconSVG
                name={'common/globus'}
                width={20}
                height={20}
                title="Иконка глобуса"
              />
            }
            title="Иконка глобуса"
          />
          <span>{link.text}</span>
          <Icon
            icon={
              <IconSVG
                name={'common/arrow'}
                width={11}
                height={10}
                title="Иконка Стрелочки"
              />
            }
            title="Иконка Стрелочки"
          />
        </span>
      </Anchor>
    )}
  </div>
);

Intro.defaultProps = {
  link: undefined,
};
